import $ from 'jquery';

$(function () {
    if ($('#home_page').length) {
        if ($('#calculator').length) {
            var setting = {
                id: '#aio-simulator',
                theme: {
                   
                },
                apiUrl: {
                    urlSettings: 'https://allinoneloan.com/api/settings',
                    urlLogin: 'https://allinoneloan.com/api/simulation/list',
                    urlSave: 'https://allinoneloan.com/api/simulation',
                    urlLoad: 'https://allinoneloan.com/api/simulation/'
                },
                widget: 'aio-simulator-widget'
            }

            // var setting = {};
            AIOSimulator(setting);

        }
    }
  
    if ($('#embed_code_page').length) {
        if ($('#calculator').length) {
            var setting = {
                id: '#aio-simulator',
                theme: {
                  
                },
                apiUrl: {
                    urlSettings: 'https://allinoneloan.com/api/settings',
                    urlLogin: 'https://allinoneloan.com/api/simulation/list',
                    urlSave: 'https://allinoneloan.com/api/simulation',
                    urlLoad: 'https://allinoneloan.com/api/simulation/'
                }
            }

            // var setting = {};
            AIOSimulator(setting);

        }
        $("#divIframe").hide();
        $("#divWidget").show();
        $("#divSearch").hide();
        $("#divLO").hide();
        $('.collapse-details').toggle();
        $('.collapse-title').click(function () { $('.collapse-details').toggle(); });  
        var acc = document.getElementsByClassName("accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = "auto";
                }
            });
        }

        $('input[type=radio][name=LOInfo]').on('change', function () {
            switch ($(this).val()) {
                case 'Yes':
                    
                    $("#divSearch").show();
                    $("#divLO").show();
                    break;
                case 'No':
                    $("#divSearch").hide();
                    $("#divLO").hide();
                    break;
            }
        });  

        $('input[type=radio][name=code]').on('change', function () {
            switch ($(this).val()) {
                case 'widget':
                    
                    $("#divIframe").hide();
                    $("#divWidget").show();
                    break;
                case 'iframe':
                    $("#divIframe").show();
                    $("#divWidget").hide();
                    break;
            }
        });

        $(document).on('change', 'input[name=loselect]', function () {

            //var dataListId = document.getElementById("loselects");
          //  var inputId = document.getElementById("loselect");
           
           // //document.getElementById('resultID').textContent = result; 
            var optionslist = $('datalist')[0].options;

            var value = $(this).val();
         
            for (var x = 0; x < optionslist.length; x++) {
                if (optionslist[x].value === value) {
                    //Alert here value
                    var m = optionslist[x].getAttribute('data-details');
                    var c = optionslist[x].getAttribute('data-com');
                   // alert(c);
                    var obj = JSON.parse(m);

                    $('input[name=loselect]').val(value);
                    $('input[name=LOFirstName]').val(obj["FirstName"]);
                    $('input[name=LOLastName]').val(obj["LastName"]);
                    $('input[name=LOTitle]').val(obj["Title"]);
                    $('input[name=LONMLS]').val(obj["Nmls"]);
                    $('input[name=LOCity]').val(obj["City"]);
                    $('input[name=LOState]').val(obj["State"]);
                    $('input[name=LOEmail]').val(obj["CorporateEmail"]);
                    $('input[name=LOPhone]').val(obj["Phone"]);
                    $('input[name=LOApplyNow]').val(obj["ApplyOnlineUrl"]);
                    $('input[name=LOImage]').val(obj["PortraitUrl"]);
                    $('input[name=LOMysite]').val(obj["MySitePath"]);
                   // alert();
                    $('input[name=LOCompanyID]').val(c);
                    $('input[name=LOLogo]').val("https://cloud.cmgfi.com/dvbdysuf5/image/upload/AIOWebsite/Shared_Images/cmg-aio-logo.svg");

                    break;
                }
            }
           
        });
     
    }


});

    
